import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock, StyledCard, StyledCardContainer, StylesCardList, ButtonWrapper, SearchField } from "./style";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { getOrdersList, getAllFolderCount, deleteOrdersData } from "../../../redux/orderSlice";

import { toast } from "react-toastify";
import moment from "moment";
import { DebounceInput } from "react-debounce-input";
import MyModal from "../../../Components/Modal";
import { encryptVal } from "../../../utility/utility";

const FutureOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const futureOrdersList = useSelector((state) => state?.order?.ordersList);
    const isLoading = useSelector((state) => state?.order?.loading);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const handleClose = () => setShow(false);

    const handleShow = (orderId) => {
        setShow(true);
        setSelectedData(orderId);
    };


    let futureMonthDataArray = [];
    const [dataTableData, setDataTableData] = useState({
        renderDataTable: false,
        data: [],
        month: "",
    });

    // console.log("dataTableData",dataTableData)

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [futureOrderData, setFutureOrderData] = useState([]);

    const deleteOrder = (orderID) => {
        let data = {
            orderId: orderID,
            permanentDeletedFlag: false,
            updatingStatus: "Deleted",
        };
        dispatch(deleteOrdersData(data)).then((res) => {
            handleClose();
            if (res.payload) {
                toast.success(`Order has been deleted successfully`, {
                    className: "toast-message",
                });
                dispatch(getAllFolderCount());
            } else {
                toast.error(`${res.message}`, {
                    className: "toast-message",
                });
            }
        }).catch((err) => {
            if (err.status === 0) {
                toast.success(`${err.message}`, {
                    className: "toast-message",
                });
            }
        });
    };

    // Filter data based on search
    useEffect(() => {
        const futureOrderArray = originalData || [];
        const searchQuery = search.toLowerCase();
        if (searchQuery) {
            const filteredData = futureOrderArray.filter((item) =>
                item.order_id.toLowerCase().includes(searchQuery) ||
                item.applicantInfo?.first_name?.toLowerCase().includes(searchQuery) ||
                item.applicantInfo?.applicant_email?.toLowerCase().includes(searchQuery)
            );
            setDataTableData((prevState) => ({
                ...prevState,
                data: filteredData,
            }));
        } else {
            setDataTableData((prevState) => ({
                ...prevState,
                data: originalData,
            }));
        }
    }, [search, originalData]);

    const columns = [
        {
            name: "Order ID",
            selector: (row) => row.order_id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.applicantInfo.first_name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.applicantInfo.applicant_email,
            sortable: true,
        },
        {
            name: "Telephone",
            selector: (row) => `+ ${row.applicantInfo.applicant_phone_code} ${row.applicantInfo.applicant_phone} `,
            sortable: true,
            width:"200px"
        },
        {
            name: "Date & Time",
            selector: (row) => row.create_ts
            ? moment(new Date(row.create_ts)).format("DD-MM-YYYY hh:mm:ss")
            : "",
            sortable: true,
        },
        {
            name: "Processing Date ",
            selector: (row) => row.processing_date
            ? moment(new Date(row.processing_date)).format("DD-MM-YYYY hh:mm:ss")
            : "",
            sortable: true,
        },
        {
            name: "Process Month",
            selector: (row) => row.process_month,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => row.process_status,
            sortable: true,
        },

        {
            name: "Action",
            width:"250px",
            selector: (row) =>
                futureOrdersList?.length ? (
                    <span>
                        <Link
                            to={`/admin/view-order/${encryptVal(row.order_id)}`}
                            style={{
                                border: "1px solid #ce1127",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: "#111",
                                margin: "5px",
                                textDecoration: "none",
                            }}
                        >
                            View
                        </Link>{" "}
                        <Link
                            to="#"
                            onClick={() => handleShow(row.id)}
                            style={{
                                textDecoration: "none",
                                border: "1px solid #e92a11",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: " #e92a11",
                                margin: "5px",
                            }}
                        >
                            Delete
                        </Link>
                    </span>
                ) : (
                    ""
                ),
        },
    ];

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "futureOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Future Order | Malaysia Admin Portal";
    }, [dispatch, navigate, page, limit]);

    useEffect(() => {
        if (futureOrdersList) {
            setOriginalData(futureOrdersList);
            fetchMonthOrder();
        }
    }, [futureOrdersList]);

    const fetchMonthOrder = () => {
        const futureMonthDataArray = []; // Initialize within function
        futureOrdersList.forEach((data) => {
            if (!futureMonthDataArray[data.process_month]) {
                futureMonthDataArray[data.process_month] = { count: 1, monthData: [data] };
            } else {
                futureMonthDataArray[data.process_month].count += 1;
                futureMonthDataArray[data.process_month].monthData.push(data);
            }
        });
        setFutureOrderData(futureMonthDataArray);
        if (dataTableData.month) {
            setDataTableData((prev) => ({
                ...prev,
                data: futureMonthDataArray[dataTableData.month]?.monthData || [],
            }));
        }
    };

    const handleClick = () => {
        window.location.reload();
    };

    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>Future Order</h1>
                    <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

                </StyledPageTitle>
                {typeof dataTableData.renderDataTable !== "undefined" && dataTableData.renderDataTable && (
                    <ButtonWrapper>
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setDataTableData({
                                    renderDataTable: false,
                                    data: [],
                                    month: "",
                                });
                            }}
                        >
                            Back
                        </button>
                    </ButtonWrapper>
                )}
                {typeof dataTableData.renderDataTable != "undefined" && !dataTableData.renderDataTable ? (
                    <StylesCardList>
                        {months.map((month, i) => (
                            <StyledCard key={i}>
                                {typeof futureOrderData[month] !== "undefined" ? (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: futureOrderData[month]?.monthData || [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                ) : (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                )}
                            </StyledCard>
                        ))}
                    </StylesCardList>
                ) : (
                    <StyledOrderBlock>
                        <SearchField>
                            <DebounceInput
                                minLength={0}
                                debounceTimeout={500}
                                type="text"
                                id="search_field"
                                name="search_field"
                                className="search-bar form-control"
                                value={searchText}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search"
                            />
                        </SearchField>
                        <div className="table-block table-responsive">
                            <DataTable
                                className="uspassport-custom-tbl"
                                columns={columns}
                                progressPending={isLoading}
                                data={dataTableData.data}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="550px"
                                pagination={dataTableData.data?.length ? true : false}
                                noDataComponent="No records found"
                                setPage={setPage}
                                setLimit={setLimit}
                                selectableRows={false}
                                // onRowClicked={(row) => console.log('Row Data:', row)} // Logs the clicked row data
                            />
                            {/* <ReactDataTable
                                data={dataTableData.data}
                                setPage={setPage}
                                setLimit={setLimit}
                                customColumns={columns}
                                orderName="futureOrder"
                            /> */}
                        </div>
                        <MyModal
                            show={show}
                            close={handleClose}
                            size={"s"}
                            confirmAlert={true}
                            noEvent={() => handleClose()}
                            yesEvent={() => deleteOrder(selectedData)}
                        ></MyModal>
                    </StyledOrderBlock>
                )}
            </StyledContainer>
        </>
    );
};

export default FutureOrders;
